import React from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import { BlogOverviewMobile } from "../components/blog/blog-overview"
import Particles from "../components/particle/particle"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props

    return (
      <Layout>
        <SEO />
        <Particles />
        <Grid
          container
          style={{
            display: "block",
            position: "relative",
            width: "100%",
            height: "100%",
            marginTop: 160,
          }}
        >
          <Container maxWidth="md">
            <BlogOverviewMobile posts={data.allMarkdownRemark.edges} />
          </Container>
        </Grid>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { sourceName: { eq: "blog" } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            tags
            title
            description
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 400, maxHeight: 360) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
